import globalApi from '@/request/globalApi/globalApi'
import axios from 'axios'

const END_POINT = '/api/fuel-product'

export const fetchFuelProductFuelVariantNegociatedRateAirportRequest = (productId, variantId, negotiatedRateId, params) => globalApi.get(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate/${negotiatedRateId}/airport`, { params })

export const patchFuelProductFuelVariantNegociatedRateAirportRequest = (productId, variantId, negotiatedRateId, airportId, discount, message) => globalApi.patch(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate/${negotiatedRateId}/airport/${airportId}`, discount, { message })

export const postFuelProductFuelVariantNegotiatedRateUploadCSVRequest = ({
  productId,
  variantId,
  contractId,
}, payload) => globalApi.post(`${END_POINT}/${productId}/fuel-variant/${variantId}/negotiated-rate/${contractId}/upload`, payload)

// TODO: create instance for download
const token = `Bearer ${localStorage.getItem('token')}` || ''
export const downloadFuelPriceNegotiatedRateCSV = () => axios.get(`${process.env.VUE_APP_API_BASE_URL}api/export/fuel-price-negotiated-rate`, {
  headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token },
}).then(response => {
  const blob = new Blob(
    [response.data],
    { type: 'text/csv' },
  )
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'export.csv' // TODO dynamic name when instance created
  link.click()
})
