<template>
  <div id="company-airport-discount-form">
    <app-sidebar-form
      form-name="company-airport-discount-form"
      :edition="companyAirportDiscountEdition"
      edition-mode-title="edit"
      :disabled="companyAirportDiscountLoading"
      :title="companyAirport.airportName"
      :entity="companyAirport"
      @update:edition="$emit('editionUpdated', $event)"
      @update:entity="$emit('companyAirportDiscountUpdated', $event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="8">
            <!-- discountType-->
            <validation-provider
              #default="{ errors }"
              :name="$t('pricing.discount_rate')"
              :vid="`company-airport-${data.item.id}-discount`"
              rules="required|decimalOrInt"
            >
              <b-form-group
                :label="$t('pricing.discount_rate')"
                class="validation-required"
                :label-for="`company-airport-${data.item.id}-discount-input-group`"
              >
                <b-form-input
                  :id="`company-airport-${data.item.id}-discount-value`"
                  v-model="data.item.discountValue"
                  :placeholder="$t('pricing.discount_rate')"
                  :state="errors[0] && false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <enum-select
              :id="`company-airport-${data.item.id}-discount-type`"
              v-model="data.item.discountType"
              :label="$t('service.package_service.discount.mode')"
              enum-class="PriceMode"
              :clearable="false"
              required
            >
              <template #option="{ value }">
                <span v-if="value === 'currency'">
                  {{ $store.state.appConfig.currency }}
                </span>
                <span v-if="value === 'percent'">%</span>
              </template>
              <template #selected-option="{ value }">
                <span v-if="value === 'currency'">
                  {{ $store.state.appConfig.currency }}
                </span>
                <span v-if="value === 'percent'">%</span>
              </template>
            </enum-select>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default {
  name: 'CompanyAirportDiscountForm',

  components: {
    AppSidebarForm,
  },

  props: {
    companyAirportDiscountEdition: {
      type: Boolean,
      required: true,
    },
    companyAirport: {
      type: Object,
      default: () => ({}),
    },
    companyAirportDiscountLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
